body {
  background-color: #eff1f4 !important;
  margin: 0;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
}

.common-table {
  .ant-table-thead > tr > th {
    padding: 10px 16px;
    border-bottom: 0;
  }

  .ant-table-placeholder {
    border-top: 0;
  }

  &_clickable {
    .ant-table-tbody > tr {
      cursor: pointer;
    }
  }
}

.expandable-table {
  .ant-table {
    background-color: transparent;
  }
  .ant-table-container {
    margin: 20px 20px 20px 0;
    background: #fff;
  }
}

.control-panel {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
