.unauthorized {
  background-color: #f6f8fa;
  height: 100vh;
  padding-top: 150px;

  &__logo {
    width: 300px;
  }

  &__desc {
    text-align: center;
    margin-top: 30px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    width: 500px;
    margin: 0 auto;
    padding: 50px 100px;
    border-radius: 4px;
    box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.07);
  }

  &__btn {
    width: 150px;
    margin-top: 10px;
  }
}
