.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }

  &__logo {
    width: 300px;
  }

  &__title {
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__login-btn {
    display: flex;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    background-color: #4285f4;
    color: #fff;
    height: 42px;
    transition: all 300ms;
    padding: 0;
    border: 0;
    outline: none;

    &:hover {
      background-color: darken(#4285f4, 10);
    }
  }

  &__btn-img-wrapper {
    background-color: #fff;
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #4285f4;
    border-radius: 4px;
  }

  &__btn-img {
    width: 20px;
    height: 20px;
  }

  &__btn-label {
    padding: 10px 20px;
    user-select: none;
  }
}
