.main {
  &__logo-container {
    margin: 16px;
  }

  &__logo {
    width: 90%;
  }

  &__header {
    background: #fff;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__sidebar {
    position: fixed;
    overflow: auto;
    height: 100vh;
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  }

  &__sidebar-footer {
    position: absolute;
    bottom: 0;
    left: 5px;
    color: #ccc;
    font-size: 12px;
  }

  &__layout {
    transition: all 300ms;
  }

  &__content {
    margin: 16px 16px;
    padding: 24px;
    background: #fff;
    min-height: 280px;
  }

  &__trigger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }
}
