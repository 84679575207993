.dashboard {
  &__header {
    margin-bottom: 30px;
    font-weight: 600;
    color: #444;
  }

  &__stats-banner {
    padding: 0 0 10px;
    margin-bottom: 40px;
  }

  &__col {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      height: 50px;
      width: 1px;
      background-color: #ccc;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__stats-title {
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    text-align: center;
  }

  &__stats-num {
    color: rgba(0, 0, 0, 0.7);
    font-size: 40px;
    text-align: center;
  }
}
